const updatePrices = (
    currency = 'SAR',
    basicPriceSar = 1499,
    basicPriceUsd = 299,
    basicPriceAed = 1499,
    proPriceSar = 10999,
    proPriceUsd = 2499,
    proPriceAed = 10999,
    bulletPriceSar = 1499,
    bulletPriceUsd = 300,
    bulletPriceAed = 1499,
) => {
    const basicPrice = document.querySelector('.basic .price');
    const bulletPrice = document.querySelector('.price-bullet');
    const proPrice = document.querySelector('.pro .price');

    if (!basicPrice || !bulletPrice || !proPrice) return;

    switch (currency) {
        case 'AED':
            basicPrice.textContent =
                basicPriceAed.toLocaleString('ru') + ' AED';
            bulletPrice.textContent =
                bulletPriceAed.toLocaleString('ru') + ' AED';
            proPrice.textContent = proPriceAed.toLocaleString('ru') + ' AED';
            break;
        case 'USD':
            basicPrice.textContent =
                basicPriceUsd.toLocaleString('ru') + ' USD';
            bulletPrice.textContent =
                bulletPriceUsd.toLocaleString('ru') + ' USD';
            proPrice.textContent = proPriceUsd.toLocaleString('ru') + ' USD';
            break;
        case 'SAR':
            basicPrice.textContent =
                basicPriceSar.toLocaleString('ru') + ' SAR';
            bulletPrice.textContent =
                bulletPriceSar.toLocaleString('ru') + ' SAR';
            proPrice.textContent = proPriceSar.toLocaleString('ru') + ' SAR';
    }
};

window.addEventListener('DOMContentLoaded', () => {
    const urlParams = new URLSearchParams(window.location.search);
    const currency = urlParams.get('currency') || 'SAR';

    const currencySwitchers = document.querySelectorAll(
        'input[name="currency"]',
    );

    const basicPrice = document.querySelector('.basic .price');
    const bulletPrice = document.querySelector('.price-bullet');
    const proPrice = document.querySelector('.pro .price');

    if (!currencySwitchers || !basicPrice || !bulletPrice || !proPrice) return;

    updatePrices(currency);

    currencySwitchers.forEach((currencySwitcher) => {
        currencySwitcher.checked = currencySwitcher.value === currency;
    });

    currencySwitchers.forEach((currencySwitcher) => {
        currencySwitcher.addEventListener('change', () => {
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.set('currency', currencySwitcher.value);
            window.history.pushState({}, '', newUrl);
            updatePrices(currencySwitcher.value);
        });
    });
});
