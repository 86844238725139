import {
    howItWorksSlider,
    howItWorksSliderMobile1,
    howItWorksSliderMobile2,
    howItWorksSliderMobile3,
} from './swiper';
import { initSliderAnimations } from './slide-icon-animations';
import {
    slider_config,
    slider_mobile_1_config,
    slider_mobile_2_config,
    slider_mobile_3_config,
} from '../config/slider-icons-animation/slider_config';

document.addEventListener('DOMContentLoaded', () => {
    const howItWorksSlidesAnimations = initSliderAnimations(slider_config);
    const howItWorksSliderMobile1Animations = initSliderAnimations(
        slider_mobile_1_config,
    );

    const howItWorkSliderMobile2Animations = initSliderAnimations(
        slider_mobile_2_config,
    );

    const howItWorksSliderMobile3Animations = initSliderAnimations(
        slider_mobile_3_config,
    );

    const initSlider = (slider, animations) => {
        animations[0].play();
        animations[slider.realIndex].on('load', () => {
            animations[slider.realIndex].resizeDrawingSurfaceToCanvas();
        });
    };

    const howItWorksSliderSlideChangeListener = (slider, animations) => {
        const currentSlideIndex = slider.realIndex;
        animations.forEach((animation, index) => {
            if (currentSlideIndex === index) {
                animation.play();
            } else {
                animation.pause();
            }
        });
        if (slider.realIndex === slider.slides.length - 1) {
            slider.mousewheel.disable();
        }
    };

    howItWorksSlider.on('init', () =>
        initSlider(howItWorksSlider, howItWorksSlidesAnimations),
    );
    howItWorksSliderMobile1.on('init', () =>
        initSlider(howItWorksSliderMobile1, howItWorksSliderMobile1Animations),
    );

    howItWorksSliderMobile2.on('init', () =>
        initSlider(howItWorksSliderMobile2, howItWorkSliderMobile2Animations),
    );

    howItWorksSliderMobile3.on('init', () =>
        initSlider(howItWorksSliderMobile3, howItWorksSliderMobile3Animations),
    );

    howItWorksSlider.init();
    howItWorksSliderMobile1.init();
    howItWorksSliderMobile2.init();
    howItWorksSliderMobile3.init();

    howItWorksSlider.on('slideChange', () =>
        howItWorksSliderSlideChangeListener(
            howItWorksSlider,
            howItWorksSlidesAnimations,
        ),
    );

    howItWorksSliderMobile1.on('slideChange', () =>
        howItWorksSliderSlideChangeListener(
            howItWorksSliderMobile1,
            howItWorksSliderMobile1Animations,
        ),
    );

    howItWorksSliderMobile2.on('slideChange', () =>
        howItWorksSliderSlideChangeListener(
            howItWorksSliderMobile2,
            howItWorkSliderMobile2Animations,
        ),
    );

    howItWorksSliderMobile3.on('slideChange', () =>
        howItWorksSliderSlideChangeListener(
            howItWorksSliderMobile3,
            howItWorksSliderMobile3Animations,
        ),
    );

    //TODO: uncomment when make true scroll without loop
    // howItWorksSlider.on('reachEnd', () => {
    //     howItWorksSlider.mousewheel.disable();
    // });
});
