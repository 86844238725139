const requirementsTooltipConfig = {
    '.combintaion-gptq': {
        title: 'Combination of GPTQ and GGML / GGUF (offloading)',
        ramText: '20 gb',
        vramText: '20 gb',
        offsetX: -20,
        offsetY: -10,
    },
    '.ggm-gguf': {
        title: 'GGML / GGUF (CPU inference)',
        vramText: '600 mb',
        ramText: '40 gb',
        offsetX: -20,
        offsetY: -10,
    },
    '.gptq': {
        title: 'GPTQ (GPU inference)',
        vramText: '40 gb',
        ramText: '60 gb',
        offsetX: -30,
        offsetY: -200,
    },
};

export default requirementsTooltipConfig;
