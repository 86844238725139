document.addEventListener('DOMContentLoaded', () => {
    const offerForm = document.querySelector('.offer-form');

    if (!offerForm) return;

    const error = offerForm.querySelector('.offer-form__input-error');
    const checkboxPolicy = offerForm.querySelector('#confirm-policy');

    offerForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const nameValue = offerForm.querySelector(
            '.offer-form__input[name="name"]',
        ).value;
        const emailValue = offerForm.querySelector(
            '.offer-form__input[name="email"]',
        ).value;
        const phoneValue = offerForm.querySelector(
            '.offer-form__input[name="phone"]',
        ).value;

        if (/[\d]/.test(nameValue)) {
            error.textContent = 'name cannot contain numbers';
        }

        if (!/\S+@\S+\.\S+/.test(emailValue)) {
            error.textContent = 'invalid email';
        }

        if (/[a-zA-Z]/.test(phoneValue)) {
            error.textContent = 'phone cannot contain letters';
        }

        if (!checkboxPolicy.checked) {
            error.textContent = 'you must agree with privacy policy';
        }

        if (!error.textContent.length) {
            offerForm.submit();
        }
    });
});
