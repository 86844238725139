import benefitsTooltipConfig from './benefitsTooltipConfig';
import requirementsTooltipConfig from './requirementsTooltipConfig';

document.addEventListener('DOMContentLoaded', () => {
    const svg = document.querySelector('#benefits-diagram');
    const benefitTooltip = document.querySelector('.tooltip');
    const requirementsTooltip = document.querySelector('.requirements-tooltip');

    if (!svg || !benefitTooltip || !requirementsTooltip) {
        return;
    }

    const requirementsTooltipTitle = requirementsTooltip.querySelector(
        '.requirements-tooltip__title',
    );
    const requirementsTooltipRAM =
        requirementsTooltip.querySelector('.specs-ram__title');
    const requirementsTooltipVRAM =
        requirementsTooltip.querySelector('.specs-vram__title');
    const titleElement = benefitTooltip.querySelector('.tooltip__title');
    const description = benefitTooltip.querySelector('.tooltip__description');

    const setBenefitTooltipContent = (title, text) => {
        titleElement.textContent = title;
        description.textContent = text;
    };

    const setRequirementsTooltipContent = (title, vramText, ramText) => {
        requirementsTooltipTitle.textContent = title;
        requirementsTooltipRAM.textContent = `${ramText}`;
        requirementsTooltipVRAM.textContent = `${vramText}`;
    };

    const positionTooltip = (
        targetElement,
        tooltipElement,
        offsetX,
        offsetY,
    ) => {
        if (!targetElement || !tooltipElement) {
            return;
        }

        const targetRect = targetElement.getBoundingClientRect();

        let left = targetRect.right + offsetX;
        let top = targetRect.bottom + offsetY;

        if (left + tooltipElement.offsetWidth > window.innerWidth) {
            left = targetRect.left - tooltipElement.offsetWidth - offsetX;
        }

        if (
            top + tooltipElement.offsetHeight >
            window.innerHeight + window.scrollY
        ) {
            top = targetRect.top - tooltipElement.offsetHeight - offsetY;
        }

        tooltipElement.style.left = left + 'px';
        tooltipElement.style.top = top + 'px';
    };

    const showTooltipForBenefitElement = (
        target,
        tooltip,
        title,
        text,
        offsetX,
        offsetY,
    ) => {
        tooltip.style.display = 'flex';
        setBenefitTooltipContent(title, text);
        positionTooltip(target, tooltip, offsetX, offsetY);
    };

    const showTooltipForRequirementElement = (
        target,
        tooltip,
        title,
        ramText,
        vramText,
        offsetX,
        offsetY,
    ) => {
        tooltip.style.display = 'flex';
        setRequirementsTooltipContent(title, vramText, ramText);
        positionTooltip(target, tooltip, offsetX, offsetY);
    };

    svg.addEventListener('mousemove', (event) => {
        for (const [selector, config] of Object.entries(
            benefitsTooltipConfig,
        )) {
            if (event.target.closest(selector)) {
                showTooltipForBenefitElement(
                    event.target,
                    benefitTooltip,
                    config.title,
                    config.text,
                    config.offsetX,
                    config.offsetY,
                );
                event.target.addEventListener('mouseleave', () => {
                    benefitTooltip.style.display = 'none';
                });
                return;
            }
        }
        for (const [selector, config] of Object.entries(
            requirementsTooltipConfig,
        )) {
            if (event.target.closest(selector)) {
                showTooltipForRequirementElement(
                    event.target,
                    requirementsTooltip,
                    config.title,
                    config.vramText,
                    config.ramText,
                    config.offsetX,
                    config.offsetY,
                );
                event.target.addEventListener('mouseleave', () => {
                    requirementsTooltip.style.display = 'none';
                });
                return;
            }
        }
    });
});
