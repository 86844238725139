const benefitsTooltipConfig = {
    '.fosters-innovation': {
        title: 'Fosters Innovation',
        text: 'Open-source LLMs encourage innovation by enabling organizations to experiment and build upon existing models. Startups, in particular, can leverage these models as a foundation for creative and unique applications.',
        offsetX: -320,
        offsetY: 0,
    },
    '.active-support': {
        title: 'Active community support',
        text: 'Open-source projects often have thriving communities of developers and experts. It means quicker issue resolution, access to helpful resources, and a collaborative environment for problem-solving.',
        offsetX: 0,
        offsetY: -318,
    },
    '.language-customization': {
        title: 'Language model customization',
        text: 'Tailoring the model to specific industry or domain needs is more manageable with open-source LLMs. Organizations can fine-tune the model to suit their unique requirements.',
        offsetX: -320,
        offsetY: -298,
    },
    '.vendor-dependency': {
        title: 'Reduced vendor dependency',
        text: 'Businesses can reduce reliance on a single vendor, promoting flexibility and preventing vendor lock-in.',
        offsetX: -323,
        offsetY: -230,
    },
    '.code-transperancy': {
        title: 'Code transparency',
        text: 'Open-source LLMs offer transparency into their underlying code, allowing organizations to inspect and validate the model’s functionality.',
        offsetX: -323,
        offsetY: -230,
    },
    '.cost-saving': {
        title: 'Cost savings',
        text: 'Open-source LLMs eliminate licensing fees, making them a cost-effective solution for enterprises and startups with tight budgets.',
        offsetX: -323,
        offsetY: -230,
    },
    '.data-security-privacy': {
        title: 'Enhanced data security and privacy',
        text: 'With open-source LLMs, organizations can deploy the model on their own infrastructure and thus, have more control over their data.',
        offsetX: -323,
        offsetY: -250,
    },
};

export default benefitsTooltipConfig;
