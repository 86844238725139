import '../scss/style.scss';
import './tooltip';
import './benefits-requirements-pulse-effect';
import './how-it-works-slider';
import './offer-form';
import './currencySwitcher';

function initializeCustomSelect(containerSelector) {
    const customSelect = document.querySelector(containerSelector);
    if (!customSelect) return;

    const trigger = customSelect.querySelector('.language-select__trigger');
    const options = customSelect.querySelectorAll('.language-select__option');
    const selectedOptionDisplay = trigger.querySelector('span');

    trigger.addEventListener('click', function () {
        customSelect.classList.toggle('open');
    });
    document.addEventListener('click', function (e) {
        if (!customSelect.contains(e.target)) {
            customSelect.classList.remove('open');
        }
    });

    options.forEach(function (option) {
        option.addEventListener('click', function () {
            options.forEach((opt) => opt.classList.remove('selected'));
            this.classList.add('selected');
            selectedOptionDisplay.innerHTML = this.innerHTML;
            customSelect.classList.remove('open');
        });
    });
}

window.addEventListener('DOMContentLoaded', () => {
    const headerBurger = document.querySelector('.header .desktop .burger');
    const mobileMenuBurger = document.querySelector(
        '.header .mobile-menu .header__logo-container .burger',
    );
    const mobileMenuContainer = document.querySelector('.mobile-menu');
    const links = mobileMenuContainer.querySelectorAll(
        '.mobile-nav .header__nav',
    );
    const smoothLinks = document.querySelectorAll('a[href^="#"]');
    const demoButtons = document.querySelectorAll('.demo-button');
    const demoModal = document.querySelector('.demo-modal');
    const showMobileMenu = () => {
        mobileMenuContainer.classList.toggle('active');
        document.body.classList.toggle('no-scroll');
    };

    links.forEach((link) => link.addEventListener('click', showMobileMenu));
    headerBurger.addEventListener('click', showMobileMenu);
    mobileMenuBurger.addEventListener('click', showMobileMenu);

    for (let smoothLink of smoothLinks) {
        smoothLink.addEventListener('click', function (e) {
            const hrefAttribute = smoothLink.getAttribute('href');
            if (hrefAttribute.startsWith('#')) {
                e.preventDefault();
                if (
                    window.location.pathname === '/' ||
                    window.location.pathname.endsWith('index.html')
                ) {
                    document.querySelector(hrefAttribute).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                } else {
                    window.location.href = '/index.html' + hrefAttribute;
                }
            }
        });
    }

    initializeCustomSelect('.language-select-wrapper .language-select');
    initializeCustomSelect('.mobile-menu-footer .language-select');

    demoButtons.forEach((demoButton) => {
        demoButton.addEventListener('click', () => {
            try {
                Calendly.initPopupWidget({
                    url: 'https://calendly.com/book-demo-calendar/30min?hide_gdpr_banner=1&background_color=000000&text_color=FFFFFF&primary_color=2BD07D',
                });
                return false;
            } catch (error) {
                alert('Recording temporary not work');
                console.error(error);
            }
        });
    });

    if (!demoModal) return;

    const watchDemoButton = document.querySelector('.watch-demo-button');
    const demoVideo = demoModal.querySelector('.demo-modal__video');
    const closeDemoModalButton = demoModal.querySelector('.demo-modal__close');

    const toggleDemoModal = (isOpen) => {
        if (!isOpen) {
            demoModal.style.display = 'none';
            return;
        }
        demoModal.style.display = 'flex';
    };

    if (!closeDemoModalButton || !watchDemoButton) return;
    watchDemoButton.addEventListener('click', () => {
        toggleDemoModal(true);
    });

    closeDemoModalButton.addEventListener('click', () => {
        toggleDemoModal(false);
        demoVideo.pause();
    });

    if (window.location.href.indexOf('#watch-demo') !== -1) {
        toggleDemoModal(true);
    }

    demoVideo.addEventListener('click', function () {
        if (demoVideo.requestFullscreen) {
            demoVideo.requestFullscreen();
        } else if (demoVideo.mozRequestFullScreen) {
            demoVideo.mozRequestFullScreen();
        } else if (demoVideo.webkitRequestFullscreen) {
            demoVideo.webkitRequestFullscreen();
        } else if (demoVideo.msRequestFullscreen) {
            demoVideo.msRequestFullscreen();
        }
    });
});
