export const howItWorksSlider = new Swiper('.how-it-works-slider', {
    slidesPerView: 1,
    init: false,
    slideToClickedSlide: true,
    mousewheel: {
        sensitivity: 1,
        releaseOnEdges: true,
    },
    loop: true,
    breakpoints: {
        768: {
            slidesPerView: 1.5,
            spaceBetween: 30,
        },
        1100: {
            slidesPerView: 1.5,
            spaceBetween: 30,
        },
        1280: {
            slidesPerView: 2.3,
            spaceBetween: 50,
        },
        1540: {
            slidesPerView: 2.35,
            spaceBetween: 100,
        },
        1740: {
            slidesPerView: 2.5,
            spaceBetween: 5,
        },
    },
});

export const howItWorksSliderMobile1 = new Swiper(
    '.how-it-works-mobile-slider-1',
    {
        slideToClickedSlide: true,
        mousewheel: {
            sensitivity: 1,
            releaseOnEdges: true,
        },
        init: false,
        spaceBetween: 20,
        slidesPerView: 1,
    },
);

export const howItWorksSliderMobile2 = new Swiper(
    '.how-it-works-mobile-slider-2',
    {
        slideToClickedSlide: true,
        mousewheel: {
            sensitivity: 1,
            releaseOnEdges: true,
        },
        init: false,
        spaceBetween: 20,
        slidesPerView: 1,
    },
);

export const howItWorksSliderMobile3 = new Swiper(
    '.how-it-works-mobile-slider-3',
    {
        slideToClickedSlide: true,
        mousewheel: {
            sensitivity: 1,
            releaseOnEdges: true,
        },
        init: false,
        spaceBetween: 20,
        slidesPerView: 1,
    },
);

export const pricesSwiper = new Swiper('.prices-swiper', {
    direction: 'horizontal',
    slidesPerView: 1.1,
    spaceBetween: 10,
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        1367: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1540: {
            slidesPerView: 3,
            spaceBetween: 90,
        },
    },
});
