export const slider_config = {
    '#slide_img1': {
        path: '../../config/slider-icons-animation/slide_1.riv',
    },
    '#slide_img2': {
        path: '../../config/slider-icons-animation/slide_2.riv',
    },
    '#slide_img3': {
        path: '../../config/slider-icons-animation/slide_3.riv',
    },
    '#slide_img4': {
        path: '../../config/slider-icons-animation/slide_4.riv',
    },
    '#slide_img5': {
        path: '../../config/slider-icons-animation/slide_5.riv',
    },
    '#slide_img6': {
        path: '../../config/slider-icons-animation/slide_6.riv',
    },
    '#slide_img7': {
        path: '../../config/slider-icons-animation/slide_7.riv',
    },
    '#slide_img8': {
        path: '../../config/slider-icons-animation/slide_8.riv',
    },
    '#slide_img9': {
        path: '../../config/slider-icons-animation/slide_9.riv',
    },
};

export const slider_mobile_1_config = {
    '#slide_img_mobile_1': {
        path: '../../config/slider-icons-animation/slide_1.riv',
    },
    '#slide_img_mobile_2': {
        path: '../../config/slider-icons-animation/slide_2.riv',
    },
    '#slide_img_mobile_3': {
        path: '../../config/slider-icons-animation/slide_3.riv',
    },
};

export const slider_mobile_2_config = {
    '#slide_img_mobile_4': {
        path: '../../config/slider-icons-animation/slide_4.riv',
    },
    '#slide_img_mobile_5': {
        path: '../../config/slider-icons-animation/slide_5.riv',
    },
    '#slide_img_mobile_6': {
        path: '../../config/slider-icons-animation/slide_6.riv',
    },
};

export const slider_mobile_3_config = {
    '#slide_img_mobile_7': {
        path: '../../config/slider-icons-animation/slide_7.riv',
    },
    '#slide_img_mobile_8': {
        path: '../../config/slider-icons-animation/slide_8.riv',
    },
    '#slide_img_mobile_9': {
        path: '../../config/slider-icons-animation/slide_9.riv',
    },
};
