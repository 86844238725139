import { Rive } from '@rive-app/canvas';

export const initSliderAnimations = (config) => {
    const slides = [];
    for (const [container, _config] of Object.entries(config)) {
        const canvas = document.querySelector(container);
        if (!canvas) continue;
        slides.push(
            new Rive({
                src: _config.path,
                canvas: canvas,
                autoplay: false,
                stateMachines: 'State Machine 1',
            }),
        );
    }
    return slides;
};
